<template>
    <div class="reportEatDesktime defaultReport">
        <div class="filter-box">
            <div class="filter-bnts ">
                <div class="bnt verticalCenter" :class="{selected:queryType==false}" @click="nowadays()" >
                    <div class="title">
                        <span>今天<br/>({{userInfo.Rpt_Date}})</span>
                    </div>
                </div>
                <div class="bnt verticalCenter" :class="{selected:queryType==true}" @click="queryType=true" v-if="ReportDataFromDays>=0">
                    <div class="title">
                        <span>按日期范围</span>
                    </div>
                </div>
                <div class="inline-block" v-if="ReportDataFromDays>=0">
                    <el-date-picker class="from-date"
                        v-model="businessHours"
                        type="daterange"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :default-time="defaultTime"
                        @change="change"
                        :disabled='queryType==false'
                        :disabled-date="disabledDate"
                    > </el-date-picker>
                </div>
                <div class="inline-block" >
                    <div class="flex-box">
                        <div class="lable-txt">餐厅:</div>
                        <div class="from-input" style="width: 170px;">
                            <el-select v-model="roomids" multiple collapse-tags @removeTag="()=>roomids=[]" >
                                <el-option :value="item.Room_AutoID" :label="item.Room_Code+'-'+ item.Room_Name" v-for="item in PosRooms" :key="item"></el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
                <div class="inline-block">
                    <div class="flex-box eat">
                        <div class="lable-txt">时段:</div>
                        <div class="from-input">
                            <el-select v-model="salesValue" placeholder="全天" multiple collapse-tags @removeTag="()=>salesValue=[]">
                                <el-option
                                    v-for="item in eatPhases"
                                    :key="item.EAT_PHASEID"
                                    :label="item.EAT_PHASENAME"
                                    :value="item.EAT_PHASEID">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
                <div class="inline-block" >
                    <div class="flex-box">
                        <div class="lable-txt">销售渠道:</div>
                        <div class="from-input" style="width: 180px;">
                            <select-tree v-model="channelAutoIDs" placeholder="请选择" :data="treeOrderChannel" title="销售渠道选择">
                            </select-tree>
                        </div>
                    </div>
                </div>
                <div class="inline-block search-flex">
                    <div class="bnt-search" @click="GetReportData()" >查询</div>
                </div>
            </div>
            <div class="right-bnts">
                <div class="bnt" @click="exportExcel()"><i class="iconfont icon-daochu"></i>导出报表</div>
            </div>
            
        </div>
        <div class="content-box clearfix">
            <div class="table-box grey-table headerCol2" ref="tableBox">
                <el-table class="el-table--scrollable-y"  :data="tableListPage" 
                    ref="tableEl"
                    border 
                    @cell-dblclick="orderDetail"
                    v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}">
                    <el-table-column fixed prop="Rpt_Date" label="营业日期" min-width="90" :formatter="shortDateFormat"></el-table-column>
                    <el-table-column fixed prop="WeekDay" label="星期" min-width="60"></el-table-column>
                    <el-table-column fixed prop="DeskNum" align="right" label="总桌数" min-width="70" data-format="number">
                        <template #default="scope">
                            <span class="link" >{{scope.row.DeskNum}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="MenNum" align="right" label="来客数" min-width="70" data-format="number"></el-table-column>
                    <el-table-column label="正常桌数">
                        <el-table-column prop="Normal_DeskNum" align="right" label="桌数" min-width="70" data-format="number">
                            <template #default="scope">
                                <span class="link" >{{scope.row.Normal_DeskNum}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="Normal_Rate" align="right" label="占比" min-width="70" data-format="number"></el-table-column>
                    </el-table-column>
                    <el-table-column label="超时桌数">
                        <el-table-column prop="Timeout_DeskNum" align="right" label="桌数" min-width="70" data-format="number">
                            <template #default="scope">
                                <span class="link" >{{scope.row.Timeout_DeskNum}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="Timeout_Rate" align="right" label="占比" min-width="70" data-format="number"></el-table-column>
                    </el-table-column>
                    <el-table-column label="未完成考核桌数">
                        <el-table-column prop="Unfinished_DeskNum" align="right" label="桌数" min-width="70" data-format="number">
                            <template #default="scope">
                                <span class="link" >{{scope.row.Unfinished_DeskNum}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="Unfinished_Rate" align="right" label="占比" min-width="70" data-format="number"></el-table-column>
                    </el-table-column>
                </el-table>
            </div>
            <table-page-btn class="fy" :data="tableList" 
                :default-index="currentPage" 
                @current-change="(index)=>currentPage=index"
                @page-change="(list)=>tableListPage=list" 
                :page-size="pagesize"></table-page-btn>
        </div>
     </div>
</template>
<script>

/**按桌号计时统计表 */
export default {
    name:'reportEatservetime',
    data(){
        return {
            userInfo:{},
            /**表格数据 */ 
            currentPage:1,
            tableList:[],
            tableListPage:[],
            pagesize:9,//每页的数据条数
            /**默认日期范围 */
            defaultTime:[new Date(),new Date()],
            /**选中日期范围 */
            businessHours:[new Date(),new Date()],
            /**true: 日期范围    false：当前营业数据*/
            queryType:false,
            //餐厅id
            roomids:[],
            //时段数据
            eatPhases:null,
            //时段
            salesValue:[],
            //渠道数据
            orderChannel:null,
            //渠道id
            channelAutoIDs:[],
            //桌台数据
            PosRooms:[],
            /**记录请求参数 */
            tempParam:{}
        }
    },
    computed:{
        //限制门店用户查看几天以内的报表, -1:不能查看历史数据，0:不限制，大于0：限制查看几天内的报表
        ReportDataFromDays(){
            return this.$cacheData?.globalVariable?.GlobalSysSetting.AllowUserViewReportDataFromDays||0
        },
        //大类数据
        foodMainCategory(){
            let foodMainCategory=[];
            this.posFoodData?.data?.map(item=>{
                let depart={
                    id:item.MainCategory_ID,
                    Name:item.MainCategory_Name,
                    children:[],
                    Hierarchy:1
                }
                if(item.FoodSubCategorys){
                    item.FoodSubCategorys.map(eat=>{
                        let chieat={
                            id:eat.SubCategory_ID,
                            Name:eat.SubCategory_Name,
                            Hierarchy:2
                        }
                        depart.children.push(chieat)
                    })
                }
                foodMainCategory.push(depart)
            })
            return foodMainCategory;
        },
        //渠道数据
        treeOrderChannel(){
            let treeData=[];
            this.orderChannel?.map((group)=>{
                let json={
                    id:group.OrderChannelKind_AutoID,
                    name:group.OrderChannelKind_Code+group.OrderChannelKind_Name,
                    children:[],
                    Hierarchy:1
                }
                if(group.OrderChannelList){
                    group.OrderChannelList.map(item=>{
                        let children= Object.assign({
                            id:item.OrderChannel_AutoID,
                            name:item.OrderChannel_Code+'-'+item.OrderChannel_Name,
                            Hierarchy:2
                        },item)
                        json.children.push(children)
                    })
                }
                treeData.push(json)
            })
            return treeData;
        }
    },
    mounted(){
        this.userInfo = this.$auth.getUserInfo();
        let Rpt_Date=new Date(this.userInfo.Rpt_Date);
        Rpt_Date.setDate(Rpt_Date.getDate()-1);
        this.businessHours=[Rpt_Date.Format("yyyy-MM-dd"),Rpt_Date.Format("yyyy-MM-dd")];

        //时段数据
        this.$cacheData.EatPhase().then((d)=>{
            if(d){
                this.eatPhases=d;
            }
        }).catch((e)=>{
            this.$message.error('加载时段数据失败：'+e.message);
            console.log('加载时段数据失败：'+e);
        })

        //渠道数据
        this.$cacheData.OrderChannel().then((d)=>{
            if(d){
                this.orderChannel=d;
            }
        }).catch((e)=>{
            this.$message.error('加载渠道数据失败：'+e.message);
            console.log('加载渠道数据失败：'+e);
        })


        this.$cacheData.EatRooms().then((d)=>{
            this.PosRooms=d;
        }).catch((e)=>{
            this.$alert("桌台数据加载失败"+e.message, "提示", {confirmButtonText: "确定"});
        });
    },
    watch:{
    },
    methods:{
        disabledDate(d){
            if(this.ReportDataFromDays>0 && this.userInfo.Rpt_Date){
                const oneDay =  24 * 3600 * 1000;
                const day = this.ReportDataFromDays * oneDay;
                let maxDate=new Date(this.userInfo.Rpt_Date).getTime()-oneDay;
                let minDate=maxDate-day;
                if(minDate>=d.getTime() || maxDate<d.getTime()){
                    return true;
                }
            }
            return false;
        },
        /**计算 一页显示多少条数据 */
        setTablePageSize(){
            if(this.$refs.tableBox){
                this.$nextTick(()=>{
                    let height=this.$refs.tableBox.clientHeight - 80;
                    this.pagesize=parseInt(height/50);
                })
            }
        },
        /**Excel导出 */
        exportExcel(){
            if(!this.$cacheData?.globalVariable?.GlobalSysSetting.AllowUserPrintReports){
                this.$message.warning('您没有导出报表权限，请先分配权限！');
                return;
            }
            this.$excelCommon.elTableToExcel({
                elTable:this.$refs.tableEl,
                titleName:"按桌号计时统计表",
                list:this.tableList
            })
        },
        /**今日 */
        nowadays(){
            this.queryType=false
            let Rpt_Date=new Date(this.userInfo.Rpt_Date);
            Rpt_Date.setDate(Rpt_Date.getDate()-1);
            this.businessHours=[Rpt_Date.Format("yyyy-MM-dd"),Rpt_Date.Format("yyyy-MM-dd")];
        },
        change(){
            if(this.queryType==false){
                this.queryType=true
            }
        },
         /*单价格式 截取两位小数 */
        priceFormat(row, column, cellValue){
            if(isNaN(cellValue)){
                return ""
            }else{
                return parseFloat(parseFloat(cellValue).toFixed(2));
            }
        },
        /*时间格式 */
        shortDateFormat(row, column, cellValue){
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            return (new Date(cellValue)).Format('yyyy-MM-dd');
        },
        /**查询 */
        GetReportData(){
            let time = JSON.parse(JSON.stringify(this.businessHours));
            let param={
                User_ID:this.userInfo?.User_ID,
                Operator_Name:this.userInfo?.Login_Name,
                DateRange_YN:this.queryType,//是否日期范围查询  true: 日期范围    false：当前营业数据
                Begin_Date:new Date(time[0]).Format("yyyy-MM-dd"),//开始日期
                End_Date:new Date(time[1]).Format("yyyy-MM-dd"), //结束日期
                Eat_PhaseIDs:this.salesValue,
                Eat_RoomIDs:this.roomids,
                Channel_IDs:this.channelAutoIDs,
            }
            const loading = this.$loading({
                text: "查询中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.DeskTimeInfoReport",param).then((d)=>{
                this.tempParam=param;
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.tableList=d.ResponseBody
                    this.setTablePageSize()
                    this.currentPage=1
                }else{
                    this.tableList=[];
                    this.currentPage=1
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                this.tableList=[]
                loading.close();
                this.$message.error(e.message);
                console.log(e);
            })
        },
        //穿透
        orderDetail(row,column){
            if(column.property=="DeskNum" || column.property=="Normal_DeskNum" || column.property=="Timeout_DeskNum" || column.property=="Unfinished_DeskNum"){//穿透 账单表 
                let DeskStatus=0;
                if(column.property=="Normal_DeskNum"){
                    DeskStatus=1;
                }else if(column.property=="Timeout_DeskNum"){
                    DeskStatus=2;
                }else if(column.property=="Unfinished_DeskNum"){
                    DeskStatus=3;
                }
                //桌台完成情况 1:正常 2:超时 3:未完成
                this.$emit("addFrame",{
                    key:"eatDesktimeOrder"+row.Rpt_Date,
                    data:{
                        Rpt_Date:this.shortDateFormat(null,null,row.Rpt_Date) ,
                        DeskStatus:DeskStatus,
                        Eat_PhaseIDs:this.tempParam.Eat_PhaseIDs,
                        Eat_RoomIDs:this.tempParam.Eat_RoomIDs,
                        Channel_IDs:this.tempParam.Channel_IDs,
                    },
                    title:'按桌号计时账单表',
                    url:'eatDesktimeOrder'
                });
            }
        },
    }
}
</script>

<style lang="scss" >
@import './reportEatDesktime.scss'
</style>